import { useParams } from "react-router-dom"

import { HomeGroupAddHomes } from "src/components/HomeGroups/HomeGroupAddHomes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { FullscreenView } from "src/ui/Modals/FullscreenView"

export function HomeGroupAddHomesRoute() {
  const { navigate } = useRouter()

  const { homeGroupId = "" } = useParams<{ homeGroupId?: string }>()
  return (
    <FullscreenView
      onClose={() => navigate(Routes.HomeGroupDetails.path(homeGroupId))}
    >
      <HomeGroupAddHomes homeGroupId={homeGroupId} />
    </FullscreenView>
  )
}
